import React from 'react';
import Layout from '../../components/layout/Layout';
import Head from '../../components/layout/Head';
import ReviewForm from '../../components/organisms/Form/ReviewForm';
import AuthWrapper from '../../components/layout/AuthWrapper';

const ReportIncorrectLearnerForm = () => {
  return (
    <AuthWrapper>
      <Head title="Report incorrect learner form" />
      <Layout
        section="review-reject-form"
        breadcrumbs={[
          { link: '/', label: 'Home' },
          { link: '/review-forms', label: 'Review forms' },
          {
            link: '/review-learner-reject-form',
            label: 'Learner reject form',
          },
        ]}
      >
        <ReviewForm userType="learner" isRejectForm />
      </Layout>
    </AuthWrapper>
  );
};

export default ReportIncorrectLearnerForm;
